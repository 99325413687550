import React, { useState, useEffect, useRef } from 'react';
import { appendNewChatMessage, appendToLastChatMessage, handleFileChange } from './main_utils';

function MainForm() {
    let message_stream = '';
    const [message, setMessage] = useState('');
    const [isWaiting, setIsWaiting] = useState(false); // State to manage waiting status
    const dotsRef = useRef(null); // Ref for the dots
    const socketRef = useRef(null);
    const retryCountRef = useRef(0);
    const [showMeelaAvatar, setShowMeelaAvatar] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const [isRecording, setIsRecording] = useState(false); // State variable to track recording status

    useEffect(() => {
        const connectWebSocket = () => {
            if (socketRef.current) {
                console.log('WebSocket is already connected or connecting.');
                return;
            }

            const ws = new WebSocket('wss://meela-api.6thsolution.com/chat/2/');

            ws.onopen = () => {
                console.log('WebSocket Client Connected');
                socketRef.current = ws;
                retryCountRef.current = 0; // Reset the retry count on successful connection
            };

            ws.onmessage = (event) => {
                setIsWaiting(false); // Stop showing dots when a message is received
                const msg = JSON.parse(event.data);
                // Find the chat window element
                const chatWindow = document.querySelector('.chat-window');
                if (msg.message === 'completed.') {
                    message_stream = "";
                } else {
                    if (message_stream == "") {
                        appendNewChatMessage(chatWindow, 'meela', msg.message);
                    } else {
                        appendToLastChatMessage(chatWindow, msg.message);
                    }
                    message_stream = message_stream + msg.message;
                }
            };

            ws.onclose = (event) => {
                console.log('WebSocket Client Disconnected', event);
                if (!event.wasClean) {
                    console.log('Reconnecting WebSocket...');
                    retryCountRef.current += 1;
                    setTimeout(connectWebSocket, Math.min(10000, (1 << retryCountRef.current) * 1000)); // Exponential backoff
                }
                socketRef.current = null; // Clear the socket reference
            };

            socketRef.current = ws;
        };

        if (!socketRef.current) {
            connectWebSocket();
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null; // Clear the socket reference on unmount
            }
        };
    }, []); // Empty dependency array ensures this runs only once

    useEffect(() => {
        // Scroll to the dots when they appear
        if (isWaiting && dotsRef.current) {
            dotsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isWaiting]);

    const handleSend = () => {
        const chatWindow = document.querySelector('.chat-window');
        if (message.trim() && socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            appendNewChatMessage(chatWindow, 'user', message);
            socketRef.current.send(JSON.stringify({ data: message }));
            setIsWaiting(true); // Start showing dots when a message is sent
            setMessage('');
        } else {
            console.error('WebSocket is not open. Message not sent.');
        }
    };

    const handleUpload = () => {
        document.getElementById('fileInput').click();
    };

    const showChatMessages = () => {
        // Show all div elements with the 'chat-message' class
        const chatMessages = document.querySelectorAll('.chat-message');
        chatMessages.forEach(message => {
            message.style.removeProperty('display');
        });
    };

    const clearCache = () => {
        // Send clear_cache command
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify({ "command": "clear_cache" }));
        } else {
            console.error('WebSocket is not open. Clear cache command not sent.');
        }
    };

    const handleMicClick = () => {
        // Show Meela's avatar in the center of the chat window and clear previous messages
        setShowMeelaAvatar(true);

        // Hide all div elements with the 'chat-message' class
        const chatMessages = document.querySelectorAll('.chat-message');
        chatMessages.forEach(message => {
            message.style.display = 'none';
        });

        const chatWindow = document.querySelector('.chat-window');
        if (chatWindow) {
            // chatWindow.innerHTML = ''; // Clear all elements in the chat window
            chatWindow.classList.remove('text-chat-window');
            chatWindow.classList.add('voice-chat-window');
        }
    };

    const handleTextModeClick = () => {
        // Show the text input field and send button
        setShowMeelaAvatar(false);
        const chatWindow = document.querySelector('.chat-window');
        if (chatWindow) {
            // chatWindow.innerHTML = ''; // Clear all elements in the chat window
            chatWindow.classList.remove('voice-chat-window');
            chatWindow.classList.add('text-chat-window');
        }
        showChatMessages();
    };

    const startRecording = () => {
        setIsRecording(true); // Set recording status to true
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorderRef.current = new MediaRecorder(stream);
                audioChunksRef.current = [];

                mediaRecorderRef.current.ondataavailable = event => {
                    audioChunksRef.current.push(event.data);
                };

                mediaRecorderRef.current.onstop = () => {
                    setIsRecording(false); // Set recording status to false
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                    // Here, you can send the audioBlob to the backend
                    // Example: socketRef.current.send(audioBlob);

                    // Optionally, convert the Blob to base64 and send it
                    const reader = new FileReader();
                    reader.readAsDataURL(audioBlob);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        socketRef.current.send(JSON.stringify({ audio: base64data }));
                    };
                };

                mediaRecorderRef.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false); // Ensure recording status is reset on error
            });
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
    };

    return (
        <div className="background-image-container justify-content-center align-items-center">
            <div className="overlay"></div>
            <div className="container content p-5">
                <div className="row w-100">
                    {/* Chat window content */}
                    <div className="chat-window text-chat-window">
                        {showMeelaAvatar && (
                            <div className="meela-avatar text-center">
                                <img src="meela.gif" alt="Meela Avatar" className="img-fluid" />
                            </div>
                        )}
                        {isWaiting && (
                            <div className="dots text-center" ref={dotsRef}>
                                <span>.</span>
                                <span>.</span>
                                <span>.</span>
                            </div>
                        )}
                    </div>

                    <div className="chat-input black-background p-3">
                        <div className="row w-100">
                            {!showMeelaAvatar && (
                                <>
                                    <div className="col-md-9">
                                        <input
                                            type="text"
                                            className="form-control black-background-input"
                                            placeholder="Type a message..."
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleSend();
                                                }
                                            }}
                                            style={{ border: 'none', outline: 'none', boxShadow: 'none', color: '#fff', backgroundColor: '#000' }}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <button
                                            className="btn btn-sm btn-success rounded-pill green-btn"
                                            onClick={handleSend}
                                        >
                                            Send  <i className="fas fa-paper-plane"></i>
                                        </button>
                                        <button
                                            className="btn btn-sm btn-info rounded-pill"
                                            onClick={handleUpload}
                                            id="uploadButton"
                                        >
                                            Upload
                                        </button>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            accept=".docx"
                                            multiple
                                            onChange={(event) => handleFileChange(event, socketRef)}
                                        />
                                        <button className='btn btn-sm btn-warning rounded-pill' onClick={clearCache}>Clear Cache</button>
                                        <button
                                            className="btn rounded-pill"
                                            style={{ padding: '0px' }}
                                            onClick={handleMicClick}
                                        >
                                            <img src="mic-btn-2.png" alt="Microphone" style={{ height: '24px', width: '24px' }} />
                                        </button>
                                    </div>
                                </>
                            )}
                            {/* show push-to-talk button otherwise */}
                            {showMeelaAvatar && (
                                <div className="col-md-12 d-flex justify-content-center align-items-center position-relative">
                                    <button
                                        className={`btn rounded-pill ${isRecording ? 'recording' : 'btn-success'}`}
                                        onMouseDown={startRecording}
                                        onMouseUp={stopRecording}
                                    >
                                        {isRecording ? (
                                            <div className="recording-indicator">
                                                <div className="recording-dot"></div>
                                                Recording...
                                            </div>
                                        ) : (
                                            <>Press to talk <img src="mic-btn.png" alt="Microphone" style={{ height: '20px', width: '14px', marginBottom: '5px' }} /></>
                                        )}
                                    </button>
                                    <button
                                        className="btn position-absolute"
                                        style={{ right: 0 }}
                                        onClick={handleTextModeClick}
                                    >
                                        <img src="keyboard-btn.png" alt="text-mode" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default MainForm;
