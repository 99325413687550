import mammoth from 'mammoth';  // Import mammoth for reading docx files


// Function to append a new chat message
export function appendNewChatMessage(chat_window, sender, message) {
    let date = new Date();
    let options = {
        month: '2-digit',   // MM
        day: '2-digit',     // DD
        year: 'numeric',    // YYYY
        hour: '2-digit',    // hh
        minute: '2-digit',  // mm
        hour12: true        // AM/PM format
    };

    let user_text_style = "color:#9DF4AB;";
    let formattedDate = date.toLocaleString('en-US', options);
    formattedDate = formattedDate.replace(',', '').replace(/\//g, '-');

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = `
        <div class="chat-message ${sender}">
            <div class="avatar-container">
                <img
                src="${sender === 'user' ? 'user.gif' : 'meela.gif'}"
                alt="User Avatar"
                class="chat-avatar"
                />
                <span class="chat-user">${sender === 'user' ? 'You' : 'Bot'}</span>
            </div>
            <div class="chat-content">
                <span class="chat-time" style="color:#B5B5B5;">${formattedDate}</span>
                <p style="${sender === 'user' ? user_text_style : ''}">
                ${message}
                </p>
            </div>
        </div>
    `;

    // Append the newly created message div to the chat window
    chat_window.appendChild(tempDiv);

    // Scroll to the new message
    tempDiv.scrollIntoView({ behavior: 'smooth', block: 'end' });
}

export function appendToLastChatMessage(chat_window, token) {
    // Find all elements with the class 'chat-message'
    const chatMessages = chat_window.querySelectorAll('.chat-message');

    // Check if there are any chat messages
    if (chatMessages.length > 0) {
        // Get the last chat message element
        const lastMessage = chatMessages[chatMessages.length - 1];

        // Append the token to the content of the last message
        lastMessage.querySelector('.chat-content').querySelector('p').textContent += token;
        // Scroll to the updated message
        lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
}

export const handleFileChange = async (event, socketRef) => {
    const files = event.target.files;
    let combinedText = "";

    for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file && file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            const reader = new FileReader();
            
            // Use a promise to handle asynchronous file reading
            const fileText = await new Promise((resolve, reject) => {
                reader.onload = async (e) => {
                    try {
                        const arrayBuffer = e.target.result;
                        const { value } = await mammoth.extractRawText({ arrayBuffer });
                        resolve(value);
                    } catch (error) {
                        console.error('Error reading DOCX file:', error);
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            });

            // Append the extracted text to the combinedText
            combinedText += fileText + "\n";
        } else {
            console.error('Please upload a valid DOCX file.');
        }
    }

    // Send the combined text over the WebSocket
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(JSON.stringify({ "command": "cache", data: combinedText }));
    } else {
        console.error('WebSocket is not open. DOCX content not sent.');
    }
};
